import React from "react"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"
import { HeroContent } from "../HeroContent"
import { ParallaxBackgroundImage } from "../ParallaxBackgroundImage"
import type { Banner } from "../HeroBanner-types"
import "./generic-hero.module.scss"

interface HeroProps {
  banner: Banner
}

export const GenericHero = ({ banner }: HeroProps) => {
  const { background_image, heading, sub_heading, buttons } = banner
  const { textBannerBackgroundColor, imageBannerColorOverlay } =
    useColorAreaSettings()

  const isTextOnly =
    background_image && background_image.value.length > 0 ? false : true

  const bannerColorOverride = textBannerBackgroundColor
    ? `${textBannerBackgroundColor}--backgroundColor`
    : ``
  const overrideBackground = imageBannerColorOverlay
    ? `${imageBannerColorOverlay}--backgroundOverlayBefore`
    : ""

  const backgroundImageUrl = background_image?.value[0]?.url,
    hasBackgroundImage = !!backgroundImageUrl
  return (
    <section
      className={`${
        isTextOnly
          ? `fr-generic-hero--default-background-color ${bannerColorOverride}`
          : "fr-generic-hero--medium-height"
      } ${overrideBackground}`}
    >
      {hasBackgroundImage && (
        <ParallaxBackgroundImage image={background_image?.value[0]} />
      )}

      <div
        className={
          isTextOnly
            ? `fr-generic-hero__banner-content--padding-large fr-container fr-container—large`
            : `fr-generic-hero__banner-content fr-container fr-container—large`
        }
      >
        <HeroContent
          baseClassName="fr-generic-hero__banner-content"
          heading={heading?.value}
          subHeading={sub_heading}
          buttons={buttons?.value}
        />
      </div>
    </section>
  )
}
